.ql-editor {
    height: 300px !important;
}

.ql-editor strong {
    font-weight: bold !important;
}

.ql-editor em {
    font-style: italic !important;
}
